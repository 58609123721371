<script lang="tsx">
import { defineComponentBaseUiProgressBar } from '@core/app/components/base/ui/BaseUiProgressBar.vue'

export default defineComponentBaseUiProgressBar({
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiProgressBar" as *;

@include progress {
    border-radius: 100vmax;
    height: 0.3125rem;
}

@include progress-bar {
    background-color: $sim-c-basic-400;
}

@include progress-value {
    background-color: $sim-c-primary-900;
    border-radius: 100vmax;
}

</style>
