import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductImageModel } from '../models/product-image.model'

class ProductImagesApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductImagesApiService() {
    return new ProductImagesApiReactiveService({
        endpoint: 'product-images',
    }, ProductImageModel)
}
