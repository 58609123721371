<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { BaseUiRatingProps } from './../BaseUiRating.vue'
import type { BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiFormGroup, BaseUiRating } from '#components'
import type { SizeProp } from '@core-types/components'
import type { FormFieldObject } from '@core-types/form'
import type { BaseUiRatingExposed } from '../BaseUiRating.vue'

export type BaseUiFormRatingProps<Sizes extends string, Colors extends string> = {
    /**
     * Where to put the label in relation to the rating component.
     * @default 'vertical'
     */
    labelPosition?: 'vertical' | 'horizontal'
} & BaseUiFormGroupProps & Omit<
    BaseUiRatingProps<Sizes, Colors>, 'editable'
>

type BaseUiFormRatingSlots = Omit<BaseUiFormGroupSlots, 'default'> & {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormRating<
    Sizes extends string = SizeProp,
    Colors extends string = '',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormRatingProps<Sizes, Colors>, BaseUiFormRatingSlots>) {
    return defineComponent(
        (props: BaseUiFormRatingProps<Sizes, Colors>, ctx) => {

            const labelId = useId()

            const ratingComp = useTemplateRef<InstanceType<typeof BaseUiRating>>('ratingComponent')

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={props.noLabelElement}
                    fullWidth={props.fullWidth}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => {

                        const label = slotData.renderLabel(
                            (
                                renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                    <>
                                        {props.label}
                                    </>
                                ))
                            ) as VNode,
                            {
                                hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                                labelId: labelId,
                                onClick: () => {
                                    if (!ratingComp.value) return
                                    (ratingComp.value as typeof ratingComp.value & BaseUiRatingExposed).focusActiveRadio()
                                },
                            }
                        )

                        const ratingComponent = (
                            <BaseUiRating
                                ref="ratingComponent"
                                modelValue={props.modelValue}
                                onUpdate:modelValue={(val: number) => ctx.emit('update:modelValue', val)}
                                modelModifiers={props.modelModifiers}
                                form={props.form}
                                onUpdate:form={(val: FormFieldObject<number | null>) => ctx.emit('update:form', val)}
                                formModifiers={props.formModifiers}

                                labelId={labelId}
                                disabled={props.disabled}
                                loading={props.loading}
                                required={slotData.isInputRequired}
                                descriptionId={slotData.inputDescriptionIds}
                                ariaLabel={props.ariaLabel}
                                ariaInvalid={slotData.isInputAriaInvalid}

                                value={props.value}
                                maxRating={props.maxRating}
                                // @ts-ignore
                                size={props.size}
                                // @ts-ignore
                                color={props.color}
                                editable
                            />
                        )

                        return [
                            ...(props.labelPosition === 'horizontal' ? [] : [label]),

                            slotData.renderAboveDescription(),

                            ...(props.labelPosition === 'horizontal' ? [
                                <div class="sim-rating__container">
                                    {[
                                        ratingComponent,
                                        label,
                                    ]}
                                </div>,
                            ] : [
                                ratingComponent,
                            ]),

                            slotData.renderBelowDescriptionAndError(),
                        ]
                    }}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                modelValue: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes, Colors>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiFormRatingProps<Sizes, Colors>['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                help: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['help']>,
                    default: options?.props?.help?.default,
                    required: options?.props?.help?.required ?? false,
                },
                descriptionAbove: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['descriptionAbove']>,
                    default: options?.props?.descriptionAbove?.default,
                    required: options?.props?.descriptionAbove?.required ?? false,
                },
                descriptionBelow: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['descriptionBelow']>,
                    default: options?.props?.descriptionBelow?.default,
                    required: options?.props?.descriptionBelow?.required ?? false,
                },
                hideRequired: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['hideRequired']>,
                    default: options?.props?.hideRequired?.default,
                    required: options?.props?.hideRequired?.required ?? false,
                },
                label: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['label']>,
                    default: options?.props?.label?.default,
                    required: options?.props?.label?.required ?? false,
                },
                noLabelElement: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['noLabelElement']>,
                    default: options?.props?.noLabelElement?.default,
                    required: options?.props?.noLabelElement?.required ?? false,
                },
                fullWidth: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes, Colors>['fullWidth']>,
                    default: options?.props?.fullWidth?.default ?? true,
                    required: options?.props?.fullWidth?.required ?? false,
                },
                errorId: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<BaseUiFormRatingProps<Sizes, Colors>['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },

                value: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes, Colors>['value']>,
                    default: options?.props?.value?.default,
                    required: options?.props?.value?.required ?? false,
                },
                maxRating: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes, Colors>['maxRating']>,
                    default: options?.props?.maxRating?.default,
                    required: options?.props?.maxRating?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },

                labelPosition: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes, Colors>['labelPosition']>,
                    default: options?.props?.labelPosition?.default,
                    required: options?.props?.labelPosition?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormRatingSlots>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: FormFieldObject<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormRating.scss" as *;

</style>
