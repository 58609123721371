<script lang="tsx">
import { defineComponentBaseUiCheckbox } from '@core/app/components/base/ui/BaseUiCheckbox.vue'

export default defineComponentBaseUiCheckbox()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiCheckbox" as *;

</style>
