import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import type { ApiModel } from '@composable-api/api.model'
import { ProductRelatedProductModel } from '../models/product-related-product.model'

class ProductRelatedProductsApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductRelatedProductsApiService() {
    return new ProductRelatedProductsApiReactiveService({
        endpoint: '/product-related-products',
    }, ProductRelatedProductModel)
}
