<script lang="tsx">
import { defineComponentBaseUiFormRating } from '@core/app/components/base/ui/form/BaseUiFormRating.vue'
import type { BaseUiRatingColors, BaseUiRatingSizes } from './BaseUiRating.vue'

export default defineComponentBaseUiFormRating<BaseUiRatingSizes, BaseUiRatingColors>()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormRating" as *;

</style>
