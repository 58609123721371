import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { type ApiServiceFetchOptions } from '@composable-api/service/api.service'
import { ProductReviewModel } from '../models/product-review.model'
import type { ApiModel, ApiModelAttributes } from '@composable-api/api.model'

class ProductReviewsApiReactiveService<M extends ProductReviewModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }

    post(data: Partial<ApiModelAttributes<ProductReviewModel> & {
        parameters: { product_review_parameter_id: number, rating: number }[]
    }>, options?: ApiServiceFetchOptions<M>) {
        return this.fetch({
            ...options,
            method: 'POST',
            body: data,
        })
    }
}

export function useProductReviewsApiService() {
    return new ProductReviewsApiReactiveService({
        endpoint: '/product-reviews',
    }, ProductReviewModel)
}
