import { ApiReactiveService, type ApiServiceUseFetchOptions } from '@composable-api/service/api.reactive-service'
import { ProductVideoModel } from '../models/product-video.model'
import type { ApiModel } from '@composable-api/api.model'

class ProductVideosApiReactiveService<M extends ApiModel> extends ApiReactiveService<M> {
    useGet<T extends boolean>(options?: ApiServiceUseFetchOptions<M, T>) {
        return this.useFetch({
            ...options,
            method: 'GET',
        })
    }
}

export default function useProductVideosApiService() {
    return new ProductVideosApiReactiveService({
        endpoint: 'product-videos',
    }, ProductVideoModel)
}
