<script lang="tsx">
import type { SlotsType } from 'vue'
import type { BaseFormElementProps, SizeProp } from '@core-types/components'
import { CoreProvideRadioGroup, CoreInternalCheckbox } from '#components'
import type { FormFieldObject } from '@core-types/form'
import type { BaseUiCheckboxExposed } from './BaseUiCheckbox.vue'

export type BaseUiRatingProps<Sizes extends string, Colors extends string> = {
    value?: number | null
    maxRating?: number
    editable?: boolean
    size?: Sizes
    color?: Colors
    labelId?: string
} & Omit<
    BaseFormElementProps<number | null>,
    'id'
>

type BaseUiRatingSlots<Sizes extends string, Colors extends string> = {
    default: {}
    item: {
        size: Sizes | undefined
        color: Colors | undefined
        isActive: boolean
    }
}

export interface BaseUiRatingExposed {
    focusActiveRadio: () => void
}

type ComponentOptions = {}

export function defineComponentBaseUiRating<
    Sizes extends string = SizeProp,
    Colors extends string = '',
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiRatingProps<Sizes, Colors>, BaseUiRatingSlots<Sizes, Colors>>) {
    return defineComponent(
        (props: BaseUiRatingProps<Sizes, Colors>, ctx) => {
            const { t } = useI18n()

            const modelValue = computed<number | null | undefined>({
                get() {
                    return props.modelValue ?? null
                },
                set(val) {
                    if (val === undefined || val === null) return
                    ctx.emit('update:modelValue', val)
                },
            })

            const formModelValue = computed<FormFieldObject<number | null> | undefined>({
                get() {
                    return props.form
                },
                set(val) {
                    if (val === undefined) return
                    ctx.emit('update:form', val)
                },
            })

            const internalValue = computed<number | null>(() => {
                return modelValue.value ?? formModelValue.value?.__v ?? props.value ?? null
            })

            const roundedValue = computed(() => Math.round(internalValue.value ?? 0))

            const isDisabled = computed<boolean>(() => !!(props.loading || props.disabled))

            const radioButtons = useTemplateRefsList<InstanceType<typeof CoreInternalCheckbox>>()

            function focusActiveRadio() {
                const activeRadio = radioButtons.value[Math.max(roundedValue.value - 1, 0)]
                if (!activeRadio) return
                (activeRadio as typeof activeRadio & BaseUiCheckboxExposed).focus()
            }

            ctx.expose({
                focusActiveRadio,
            } satisfies BaseUiRatingExposed)

            return () => (
                <div
                    class={['sim-rating', {
                        'sim-rating--editable': props.editable,
                        'sim-rating--disabled': isDisabled.value,
                        [`sim-rating--c-${props.color}`]: props.color,
                    }]}
                    aria-label={!props.labelId
                        ? props.ariaLabel ? props.ariaLabel : t('_core_simploshop.labels.total_rating', [roundedValue.value, props.maxRating])
                        : undefined
                    }
                    aria-labelledby={props.labelId}
                    role="group"
                >
                    {
                        props.editable
                            ? (
                                <CoreProvideRadioGroup
                                    v-model={modelValue.value}
                                    v-model:form={formModelValue.value}
                                >
                                    {
                                        Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                            const rating = i + 1

                                            return (
                                                <CoreInternalCheckbox
                                                    ref={(el) => radioButtons.value.set(el)}
                                                    class={['sim-rating__item-wrapper', {
                                                        'sim-rating__item-wrapper--active': rating <= roundedValue.value,
                                                    }]}
                                                    value={rating}
                                                    required={props.required}
                                                    disabled={props.disabled}
                                                    descriptionId={props.descriptionId}
                                                    ariaInvalid={props.ariaInvalid}
                                                    ariaLabel={t('_core_simploshop.accessibility.rate_as', [rating, props.maxRating ?? 0])}
                                                    type="radio"
                                                >
                                                    {{
                                                        radio: () => <div class="sim-rating__item">
                                                            {
                                                                renderSlot(ctx.slots.item, options?.slots?.item, {
                                                                    size: props.size,
                                                                    color: props.color,
                                                                    isActive: rating <= roundedValue.value,
                                                                })
                                                            }
                                                        </div>,
                                                    }}
                                                </CoreInternalCheckbox>
                                            )
                                        })
                                    }
                                </CoreProvideRadioGroup>

                            )
                            : Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                const rating = i + 1

                                // NON-EDITABLE RATING
                                return <span
                                    class={['sim-rating__item', {
                                        'sim-rating__item--active': rating <= roundedValue.value,
                                    }]}
                                    aria-hidden="true"
                                >
                                    {
                                        renderSlot(ctx.slots.item, options?.slots?.item, {
                                            size: props.size,
                                            color: props.color,
                                            isActive: rating <= roundedValue.value,
                                        })
                                    }
                                </span>
                            })
                    }
                </div>
            )
        },
        {
            props: {
                modelValue: {
                    type: Number as PropType<BaseUiRatingProps<Sizes, Colors>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                modelModifiers: {
                    type: Object as PropType<BaseUiRatingProps<Sizes, Colors>['modelModifiers']>,
                    default: options?.props?.modelModifiers?.default,
                    required: options?.props?.modelModifiers?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiRatingProps<Sizes, Colors>['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                formModifiers: {
                    type: Object as PropType<BaseUiRatingProps<Sizes, Colors>['formModifiers']>,
                    default: options?.props?.formModifiers?.default,
                    required: options?.props?.formModifiers?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes, Colors>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes, Colors>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes, Colors>['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<BaseUiRatingProps<Sizes, Colors>['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiRatingProps<Sizes, Colors>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes, Colors>['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                value: {
                    type: Number as PropType<BaseUiRatingProps<Sizes, Colors>['value']>,
                    default: options?.props?.value?.default,
                    required: options?.props?.value?.required ?? false,
                },
                maxRating: {
                    type: Number as PropType<BaseUiRatingProps<Sizes, Colors>['maxRating']>,
                    default: options?.props?.maxRating?.default ?? 5,
                    required: options?.props?.maxRating?.required ?? false,
                },
                editable: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes, Colors>['editable']>,
                    default: options?.props?.editable?.default,
                    required: options?.props?.editable?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiRatingProps<Sizes, Colors>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
                color: {
                    // @ts-ignore
                    type: String as PropType<BaseUiRatingProps<Sizes, Colors>['color']>,
                    default: options?.props?.color?.default,
                    required: options?.props?.color?.required ?? false,
                },
                labelId: {
                    type: String as PropType<BaseUiRatingProps<Sizes, Colors>['labelId']>,
                    default: options?.props?.labelId?.default,
                    required: options?.props?.labelId?.required ?? false,
                },

            },
            slots: Object as SlotsType<BaseUiRatingSlots<Sizes, Colors>>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: FormFieldObject<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiRating.scss" as *;

</style>
