<template>
    <div>
        <div :id="productDescriptionId" class="sim-product-detail__nav scroll-m-header flex flex-col md:flex-row md:gap-5">

            <UiTabSwitcherRibbon
                v-model="selectedTabOption"
                class="mt-6 justify-center"
                :options="tabOptions"
            />
        </div>

        <!--  TAB 1 (description)  -->
        <CoreUiTabContainer
            :option="tabOptions[0]"
            :selected-option="selectedTabOption"
            class="sim-product-detail-description"
        >
            <!--  PRODUCT DESCRIPTION  -->
            <div class="sim-wysiwyg-text" v-html="product.description" />

            <div v-if="product.getCommonProperties()?.length" class="max-w-sm">
                <h2 class="sim-h6 mb-8">
                    {{ $t('product.params') }}
                </h2>

                <UiBasicList
                    :items="product.getCommonProperties() ?? []"
                    left-column-key="property_name"
                    right-column-key="attribute_name"
                />
            </div>
        </CoreUiTabContainer>

        <!--  TAB 2 (rating)  -->
        <CoreUiTabContainer
            :option="tabOptions[1]"
            :selected-option="selectedTabOption"
            :focusable="false"
            class="sim-product-detail-description"
        >
            <div class="flex flex-col items-center justify-between gap-4 md:flex-row md:gap-8">
                <div v-if="product?.rating" class="flex items-center gap-6">
                    <span class="sim-h6">
                        {{ product.rating.toFixed(1) }}
                    </span>

                    <div class="flex gap-3">
                        <BaseUiRating :value="product.rating" />

                        <span class="sim-text-normal text-simplo whitespace-nowrap">
                            {{ $t('product.rating.reviews_count', productReviews?.getMeta()?.records ?? 0) }}
                        </span>
                    </div>
                </div>

                <BaseUiButton
                    class="max-md:w-full md:ml-auto"
                    variant="outlined"
                    color="primary"
                    @click="isAddReviewModalOpen = true"
                >
                    {{ $t('product.rating.add_review') }}
                    <template #trailing>
                        <IconStar :filled="false" />
                    </template>
                </BaseUiButton>
            </div>


            <!--  PARAMETER RATINGS  -->
            <div class="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2">
                <div v-for="paramRating in product.getReviewParameterRatings()" :key="paramRating.label">
                    <div class="sim-text-normal sim-font-bold mb-3">
                        {{ paramRating.label }}
                    </div>

                    <UiProgressBarMonochrome :progress="paramRating.percentage" />
                </div>
            </div>


            <div v-if="!productReviews?.getMeta()?.records" class="mx-auto max-w-4xl">
                <h2 class="sim-h4 mb-6">
                    {{ $t('product.rating.empty_title') }}
                </h2>

                <p class="sim-error-text mb-7">
                    {{ $t('product.rating.empty_text') }}
                </p>
            </div>

            <template v-else>
                <div ref="tableInfoWrapperEl" class="flex scroll-mt-40 items-center justify-between gap-8">
                    <UiTableInfo
                        v-model="pagination"
                        range-translation="filters.reviews_range"
                        :pagination="productReviews?.getMeta()"
                        hide-per-page
                    />
                </div>

                <ProductReviewsList
                    :items="productReviews?.getItems() ?? []"
                    :loading="productReviewsStatus === 'pending'"
                />

                <div v-if="pagination" class="flex items-center justify-between gap-8">
                    <BaseUiPagination
                        :aria-label="$t('accessibility.pagination_for_order_list')"
                        :meta="productReviews?.getMeta()"
                        :loading="productReviewsStatus === 'pending'"
                        @change="pagination.page = $event"
                    />

                    <!--  TODO: uncomment when accumulating reviews is implemented  -->
                    <!--                    <BaseUiButton-->
                    <!--                        v-if="productReviews?.getMeta()?.page !== productReviews?.getMeta()?.total_pages"-->
                    <!--                        color="primary"-->
                    <!--                        size="large"-->
                    <!--                        :loading="productReviewsStatus === 'pending'"-->
                    <!--                        @click="pagination.page = pagination.page + 1"-->
                    <!--                    >-->
                    <!--                        <template #leading>-->
                    <!--                            <IconArrowRight rotate="right" class="text-simplo" />-->
                    <!--                        </template>-->

                    <!--                        {{ $t('filters.display_more_reviews', productReviews?.getMeta()?.per_page ?? 0) }}-->
                    <!--                    </BaseUiButton>-->
                </div>
            </template>
        </CoreUiTabContainer>

        <!--  PRODUCT ATTACHMENTS  -->
        <div v-if="product.attachments?.length" class="mt-8">
            <h2 class="sim-h6 mb-8">
                {{ $t('product.for_download') }}
            </h2>
            <div class="sim-product-detail-download__attachments-box">
                <UiFileDownloadBox
                    v-for="attachment in product.attachments"
                    :key="attachment.id!"
                    :name="attachment.name!"
                    :download-url="attachment.downloadUrl!"
                />
            </div>
        </div>

        <!--  TODO: create a ProductReviewModal component to load the form lazily too  -->
        <LazyBaseModal
            v-if="wasAddReviewModalMounted"
            v-model="isAddReviewModalOpen"
            size="md"
        >
            <template #header>
                <UiModalHeader :title="$t('product.rating.add_review')" />
            </template>

            <div class="mb-8">
                {{ $t('product.rating.text') }}
                "<strong class="sim-font-bold">{{ product.name }}</strong>".
            </div>

            <ProductReviewForm
                :parameters="product.productReviewParameters"
                :on-submit="handleProductReviewFormSubmit"
            />
        </LazyBaseModal>
    </div>
</template>

<script setup lang="ts">
import type { schema as ProductReviewFormSchema } from './ProductReviewForm.vue'
import type { ProductReviewForm } from '#components'
import type { Pagination } from '@core/app/composables/pagination'
import { ProductReviewModel } from '@simploshop-models/product-review.model'
import type { ApiResponse } from '@composable-api/api.response'

const {
    product,
    productReviews,
    productReviewsStatus,
    productDescriptionId,
} = defineProps<{
    product: InstanceType<typeof ProductModel>
    productReviews: ApiResponse<ProductReviewModel> | undefined
    productReviewsStatus: string // TODO: add ReturnType<typeof useFetch>['status'] when a bug with unwrapping the value in the template is fixed
    selectedVariation?: InstanceType<typeof ProductVariationModel> | null
    productDescriptionId: string
}>()

const tableInfoWrapperEl = useTemplateRef<HTMLDivElement>('tableInfoWrapperEl')

watch(() => productReviews, () => {
    tableInfoWrapperEl.value?.scrollIntoView({ behavior: 'smooth' })
})

const { t } = useI18n()

const { tabOptions, selectedTabOption } = useTabSwitcher(() => [
    t('product.description'),
    t('product.rating.title'),
])

// PRODUCT REVIEWS ----------------------------------------------------------------------
const productReviewsService = useProductReviewsApiService()

const pagination = defineModel<Pagination>('reviewsPagination')

const { is: isAddReviewModalOpen, was: wasAddReviewModalMounted } = useLazyMount()

const { notifySuccess } = useNotifications()

async function handleProductReviewFormSubmit(payload: ProductReviewFormSchema) {
    await productReviewsService.post({
        [ProductReviewModel.ATTR_PRODUCT_ID]: product.id!,
        [ProductReviewModel.ATTR_COMMENT]: payload.comment,
        [ProductReviewModel.ATTR_RATING]: payload.rating,
        [ProductReviewModel.ATTR_NAME]: payload.name,
        parameters: !Object.keys(payload.parameters).length ? undefined : Object.entries(payload.parameters)
            .filter(([_, value]) => value !== undefined)    // filter out empty values
            .map(([key, value]) => ({ product_review_parameter_id: parseInt(key), rating: value! /* TODO: remove type assertion after ts upgrade */ })),   // transform into BE format
    })

    isAddReviewModalOpen.value = false
    notifySuccess(t('product.rating.success'))
}

</script>

<style lang="scss" scoped>
.sim-product-detail-description {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    padding: 1rem;

    @include sim-text-large;
    line-height: 2rem;

    border: 1px solid $sim-c-basic-400;

    @include more-than(md){
        padding: 2.5rem;
    }
}

.sim-product-detail-download__attachments-box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.625rem;

    @include more-than(xxl){
        grid-template-columns: repeat(2, 1fr);
    }
}

</style>
