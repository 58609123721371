<template>
    <CoreUiForm
        ref="formComponent"
        v-slot="{ formData, globalFormError, isFormSubmitting }"
        :form="form"
        notify-on-error="all"
    >
        <CoreUiFormRow>
            <BaseUiFormRating
                v-model:form="formData.rating"
                :label="$t('product.rating.total')"
                size="lg"
            />
        </CoreUiFormRow>

        <CoreUiFormRow v-if="!loggedIn">
            <!--  NAME INPUT  -->
            <BaseUiFormInput
                v-model:form="formData.name"
                color="primary"
                :label="$t('labels.name')"
                :placeholder="$t('labels.your_name')"
                autocomplete="name"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <!--  REVIEW TEXTAREA  -->
            <BaseUiFormInput
                v-model:form="formData.comment"
                type="textarea"
                color="primary"
                :rows="5"
                :label="$t('labels.review')"
                :placeholder="$t('labels.your_review')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow v-if="parameters?.length" orientation="vertical">
            <BaseUiFormLabel>
                {{ $t('product.rating.parameters_rating') }}
            </BaseUiFormLabel>

            <BaseUiFormRating
                v-for="parameter in parameters"
                :key="parameter.id!"
                v-model:form="formData.parameters[parameter.id!] as FormFieldObject<number>"
                label-position="horizontal"
                :label="parameter.name ?? '-'"
                size="lg"
                color="primary"
            />
        </CoreUiFormRow>

        <CoreUiFormRow indented>
            <BaseUiButton
                class="grow"
                color="primary"
                type="submit"
                :aria-describedby="globalFormError.id"
                :loading="isFormSubmitting"
                size="large"
            >
                {{ $t('labels.submit') }}
            </BaseUiButton>
        </CoreUiFormRow>

        <BaseUiFormError v-if="globalFormError.message" :error-id="globalFormError.id">
            {{ globalFormError.message }}
        </BaseUiFormError>
    </CoreUiForm>
</template>

<script lang="ts" setup>
import type { ComponentInstance } from '@core-types/utility'
import { CoreUiForm } from '#components'
import { z } from 'zod'

const {
    onSubmit,
    parameters,
} = defineProps<{
    onSubmit: (data: z.infer<typeof schema.value>) => void | Promise<void>
    parameters?: InstanceType<typeof ProductReviewParameterModel>[]
}>()

const { loggedIn } = useAuth()

const formComponent = useTemplateRef<ComponentInstance<typeof CoreUiForm>>('formComponent')
const schema = computed(() => z.object({
    rating: z.number(),
    ...zRequiredIf({
        name: z.string(),
    }, !loggedIn.value),
    comment: z.string(),

    parameters: z.object(Object.fromEntries(parameters?.map((parameter) => [
        parameter.id,
        z.number().optional(),
    ]) ?? [])) as z.ZodObject<{ [parameterID: number]: z.ZodOptional<z.ZodNumber> }>,
}))

const form = useForm({
    schema: schema,
    onSubmitReactive: () => onSubmit,
})

export type schema = z.infer<typeof schema.value>

defineExpose({
    formComponent: formComponent,
})

</script>

<style lang="scss" scoped>

</style>
