<template>
    <div class="sim-product-detail-gallery">
        <CoreSwiperWrapper>
            <CoreSwiperWrapper>
                <CoreSwiper type="gallery-thumbs"
                            direction="horizontal"
                            direction-vertical-after="xxl"
                            class="sim-product-detail-gallery__thumbs"
                            :slides="productMedia"
                >
                    <template #slide="{ slide, active }">
                        <ProductGalleryItem
                            :media="slide"
                            :active="active"
                            type="thumb"
                        />
                    </template>
                </CoreSwiper>
            </CoreSwiperWrapper>

            <UiCard large class="sim-product-detail-gallery__main">
                <div class="sim-gallery__info">
                    <ProductFavoriteButton
                        :product="product"
                        :variation="selectedVariation"
                    />
                </div>

                <CoreSwiper type="gallery-main" :slides="productMedia" class="h-full">
                    <template #slide="{ slide }">
                        <ProductGalleryItem :media="slide" type="main" />
                    </template>
                </CoreSwiper>
            </UiCard>
        </CoreSwiperWrapper>
    </div>
</template>

<script setup lang="ts">

const {
    product,
    productImages,
    productVideos,
    selectedVariation,
} = defineProps<{
    product: InstanceType<typeof ProductModel>
    productImages: InstanceType<typeof ProductImageModel>[]
    productVideos: InstanceType<typeof ProductVideoModel>[]
    selectedVariation?: InstanceType<typeof ProductVariationModel> | null
}>()

const productMedia = computed(() => {
    const hasVariationImages = productImages.some(image => image.productVariationId === selectedVariation?.id)
    const images = productImages.filter(image => selectedVariation
        // show only variation images if variation is selected or, if the variation doesn't have images, show only default product images
        ? hasVariationImages ? image.productVariationId === selectedVariation.id : image.productVariationId === null
        // show default product images if no variation is selected
        : image.productVariationId === null
    )

    const defaultImages = images.filter(image => image.isDefault)
    const nonDefaultImages = images.filter(image => !image.isDefault)

    return [...defaultImages, ...nonDefaultImages, ...productVideos]
})

</script>

<style lang="scss" scoped>
.sim-product-detail-gallery {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    margin-bottom: 1rem;

    @include more-than(lg) {
        margin-bottom: 0;
    }

    @include more-than(xxl) {
        display: grid;
        grid-template-columns: 7.25rem calc(100% - 7.25rem - 1.125rem);
        grid-gap: 1.125rem;
        height: 40rem;
    }
}

.sim-product-detail-gallery__thumbs {
    max-height: 40rem;
    overflow: hidden;
    order: 2;

    @include more-than(xxl) {
        order: 1;
    }
}

.sim-product-detail-gallery__main {
    position: relative;
    order: 1;
    height: 17.375rem;

    @include more-than(sm) {
        height: 25rem;
    }

    @include more-than(xxl) {
        height: 40rem;
        order: 2;
    }
}

.sim-gallery__info {
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    z-index: 1;
}
</style>
