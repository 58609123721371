<script lang="tsx">
import { defineComponentBaseUiRating } from '@core/app/components/base/ui/BaseUiRating.vue'
import { IconStar } from '#components'
import type { CoreIconSizes } from '@core-types/components/CoreIcon'

export type BaseUiRatingSizes = CoreIconSizes
export type BaseUiRatingColors = 'simplo' | 'primary'

export default defineComponentBaseUiRating<BaseUiRatingSizes, BaseUiRatingColors>({
    props: {
        size: {
            default: 'md',
        },
        color: {
            default: 'simplo',
        },
    },
    slots: {
        item: ({ size }) => <IconStar size={size} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiRating" as *;

@include item {
    transition: color $sim-trans-time-short $sim-timing, fill $sim-trans-time-short $sim-timing;
}

@include wrapper--color('simplo') {
    color: $sim-c-simplo;

    @include item--active('&') {
        fill: $sim-c-simplo;
    }

    @include item--editable--before-hover('&') {
        color: $sim-c-simplo-darkest;
        fill: $sim-c-simplo-darkest;
    }
}

@include wrapper--color('primary') {
    color: $sim-c-primary-800;

    @include item--active('&') {
        fill: $sim-c-primary-800;
    }

    @include item--editable--before-hover('&') {
        color: $sim-c-primary-600;
        fill: $sim-c-primary-600;
    }
}

@include item--disabled {
    color: $sim-c-basic-400;
}

@include item--editable--after-hover {
    fill: transparent;
}

</style>
