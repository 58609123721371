<!--  eslint-disable vue/multi-word-component-names  -->
<template>
    <CoreContainer class="sim-product">
        <template v-if="productResponse?.getItem()">
            <!--  TOP PART (gallery, description, controls) -------------------------------------------------------  -->
            <BaseContainerContent class="sim-product__panel-wrapper" :padding="{ top: 'none' }">
                <!--  NAME FOR MOBILE  -->
                <div class="sim-product__name flex flex-col items-start">
                    <div class="flex flex-row items-center justify-between gap-2">
                        <span class="sim-font-bold">
                            {{ productResponse!.getItem()!.brand?.name }}
                        </span>

                        <!-- TODO - product rating -->
                    </div>

                    <div class="sim-h1">
                        {{ productResponse!.getItem()!.getName(selectedVariation) }}
                    </div>
                </div>

                <!--  GALLERY  -->
                <ProductDetailGallery
                    :product="productResponse!.getItem()!"
                    :product-images="productImagesResponse!.getItems()!"
                    :product-videos="productVideosResponse!.getItems()!"
                    :selected-variation="selectedVariation"
                    class="sim-product__gallery"
                />

                <div class="sim-product__controls">
                    <!--  NAME, DESCRIPTION FOR DESKTOP  -->
                    <ProductDetailPanel
                        class="sim-product__controls-name"
                        :product="productResponse!.getItem()!"
                        :product-description-id="productDescriptionId"
                        :selected-variation="selectedVariation"
                    />

                    <!--  CONTROLS  -->
                    <ProductDetailControls
                        v-model="selectedVariation"
                        :product="productResponse!.getItem()!"
                        :product-variations="variationsResponse?.getItems() ?? []"
                        :variations-loading="variationsResponseStatus === 'pending' || variationsResponseStatus === 'idle'"
                    />
                </div>

                <!--  DESCRIPTION  -->
                <ProductDetailDescription
                    v-model:reviews-pagination="reviewsPagination"
                    class="sim-product__description"
                    :product="productResponse!.getItem()!"
                    :product-reviews="productReviews"
                    :product-reviews-status="productReviewsStatus"
                    :selected-variation="selectedVariation"
                    :product-description-id="productDescriptionId"
                />
            </BaseContainerContent>


            <!--  RELATED PRODUCTS  -->
            <BaseContainerContent v-if="productRelatedProductsResponse?.getItems().length" :padding="{ top: 'none', bottom: 'large' }">
                <ProductDetailRelatedProducts
                    :products="relatedProductsResponse?.getItems() ?? []"
                />
            </BaseContainerContent>
        </template>
    </CoreContainer>
</template>

<script lang="ts" setup>
import { ProductRelationType } from '@sim-api-enums/product'
import { PRODUCT_CARD_EMBEDS } from '../assets/ts/constants/embeds'

const { page, syncedPage } = useCurrentPage()
const { t } = useI18n()

const productDescriptionId = computed(() => t('product.description_id'))

const { pagination: reviewsPagination } = usePagination({
    perPage: 4,
})

const [
    { data: productResponse, refresh: refreshProduct },
    { data: productRelatedProductsResponse },
    { data: productImagesResponse },
    { data: productVideosResponse },
    { data: productReviews, status: productReviewsStatus  },
    { data: variationsResponse, status: variationsResponseStatus },
] = await Promise.all([
    useProductsApiService()
        .embed([
            ProductModel.EMBED_PROPERTIES,
            ProductModel.EMBED_DISCOUNTED_TAXED_PRICE,
            ProductModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductModel.EMBED_DISCOUNT_PERCENTS,
            ProductModel.EMBED_ATTACHMENTS,
            ProductModel.EMBED_PRODUCT_AVAILABILITY,
            ProductModel.EMBED_PRODUCT_VARIATION_PROPERTIES,
            ProductModel.EMBED_CUSTOMER_PRODUCT_WISH_LIST_ITEM_ID,
            ProductModel.EMBED_CUSTOMER_PRODUCT_VARIATIONS_WISH_LIST_ITEMS,
            ProductModel.EMBED_BRAND,
            ProductModel.EMBED_RATING,
            ProductModel.EMBED_PRODUCT_REVIEW_PARAMETERS,
            ProductModel.EMBED_PRODUCT_REVIEW_PARAMETER_RATINGS,
        ])
        .forId(page.value?.model?.id)
        .useGet(),  // TODO: add error handling for the case when the model id is not present
    useProductRelatedProductsApiService()
        .whereEquals(ProductRelatedProductModel.ATTR_RELATION_TYPE, ProductRelationType.RELATED)
        .whereEquals(ProductRelatedProductModel.ATTR_MAIN_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .onlyAttrs(ProductRelatedProductModel.ATTR_RELATED_PRODUCT_ID)
        .useGet(),
    useProductImagesApiService()
        .whereEquals(ProductImageModel.ATTR_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .sortByAsc(ProductImageModel.ATTR_POSITION)
        .embed([
            ProductImageModel.EMBED_IMAGE_URL,
        ])
        .useGet(),
    useProductVideosApiService()
        .whereEquals(ProductVideoModel.ATTR_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .useGet(),
    useProductReviewsApiService()
        .setPagination(reviewsPagination)
        .whereEquals(ProductReviewModel.ATTR_PRODUCT_ID, page.value!.model!.id)
        .embed([
            ProductReviewModel.EMBED_CUSTOMER_FULL_NAME,
        ])
        .sortByDesc(ProductReviewModel.ATTR_CREATED_AT)
        .useGet(),
    // PRODUCT VARIATIONS for the controls
    useProductVariationsApiService()
        .embed([
            ProductVariationModel.EMBED_COMPUTED_TAXED_PRICES,
            ProductVariationModel.EMBED_PROPERTIES,
            ProductVariationModel.EMBED_PRODUCT_AVAILABILITY,
            ProductVariationModel.EMBED_DISCOUNT_PERCENTS,
        ])
        .whereEquals(ProductVariationModel.ATTR_PRODUCT_ID, page.value!.model!.id) // TODO: add error handling for the case when the model id is not present
        .whereEquals(ProductVariationModel.ATTR_IS_ACTIVE, true)
        .disablePagination()
        .useGet(),
])

const { selectedVariation } = useProductVariationSelector(
    () => variationsResponse.value?.getItems() ?? [],
    () => productResponse.value?.getItem() ?? null
)

// RELATED PRODUCTS

const relatedProductIds = computed<number[]>(() => productRelatedProductsResponse.value?.getItems().map(product => product.relatedProductId!) ?? [])
const [
    { data: relatedProductsResponse },
] = [
    // RELATED PRODUCTS
    useProductsApiService()
        .embed([
            ...PRODUCT_CARD_EMBEDS,
        ])
        .whereIn(ProductModel.ATTR_ID, relatedProductIds)
        .useGet({
            server: false,
            lazy: true,
            immediate: !!relatedProductIds.value.length,
        }),
]

</script>

<style lang="scss" scoped>
.sim-product__panel-wrapper {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include more-than(lg) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem 6rem;
        grid-template-areas:
            "gallery controls"
            "description -";
    }

    @include more-than(xxl) {
        grid-template-columns: 60% 1fr;
        gap: 4rem 5.5rem;
    }
}

.sim-product__name {
    order: -1;
    grid-area: name;

    @include more-than(lg) {
        display: none;
    }
}

.sim-product__gallery {
    grid-area: gallery;
}

.sim-product__controls {
    grid-area: controls;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include more-than(lg) {
        position: sticky;
        top: calc(#{$sim-navbar-height} + 2rem);
    }
}

.sim-product__controls-name {
    display: none;

    @include more-than(lg) {
        display: block;
    }
}

.sim-product__description {
    grid-area: description;
}
</style>
