<script lang="tsx">
import { defineComponentCoreUiTabSwitcher } from '@core/app/components/core/ui/tab/CoreUiTabSwitcher.vue'

export default defineComponentCoreUiTabSwitcher()

</script>

<style lang="scss" scoped>
@use '@core-scss/components/CoreUiTabSwitcher.scss' as *;

@include tab-switcher {
    flex-direction: column;

    background: $sim-c-basic-200;
    border-radius: $sim-border-radius-sm $sim-border-radius-sm 0 0;

    @include more-than(md){
        flex-direction: row;
        gap: 1.25rem;

        background: none;
        border-radius: unset;
    }
}

@include tab {
    padding: 1.25rem 1.75rem;
    background: $sim-c-basic-200;
    text-transform: uppercase;

    margin-bottom: -1px;

    @include sim-text-small;
    @include sim-font-bold;

    border: 1px solid $sim-c-basic-200;
    border-radius: $sim-border-radius-sm $sim-border-radius-sm 0 0;

    transition: background-color $sim-trans-time-short $sim-timing, color $sim-trans-time-short $sim-timing;

    @include more-than(md){
        border-bottom: 1px solid $sim-c-basic-400;
    }
}

@include tab--hover {
    background: $sim-c-basic-300;
}

@include tab--active {
    background: $sim-c-white;
    color: $sim-c-simplo;

    border: 1px solid $sim-c-basic-400;
    border-bottom: 1px solid $sim-c-white;

    order: 1;

    @include more-than(md){
        order: initial;
    }
}

</style>
